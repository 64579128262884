
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import InitialName from "@/app/ui/components/initial-name/index.vue";
import { BulkController } from "@/app/ui/controllers/BulkController";
import { BulkDetail } from "@/domain/entities/Bulk";
import {
  BulkUploadType,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import LoadingDetail from "./loading-detail.vue";

@Options({
  name: "DetailBulk",
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    }
  },
  components: {
    InitialName,
    LoadingDetail
  }
})
export default class extends Vue {
  get typeData() {
    return BulkUploadType;
  }
  activityTypeName(value: string) {
    return this.typeData.find((e: any) => e.value === value?.toLowerCase())
      ?.name;
  }

  // Get detail
  get detailData(): BulkDetail {
    return BulkController.detailBulk;
  }

  get isLoading() {
    return BulkController.isLoading;
  }

  isShowCounting(status: string) {
    switch (status.toLowerCase()) {
      case "waiting":
      case "failed":
        return false;

      default:
        return true;
    }
  }

  isShowFailedAttachment(status: string) {
    let isShow = false;
    switch (status.toLowerCase()) {
      case "success":
      case "partially":
      case "failed":
        isShow = true;
        break;
    }

    return !!this.detailData.failed_file && isShow;
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  formatDateToDateString(val: string) {
    return dateToDateString(val);
  }
}
