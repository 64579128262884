
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { replaceWhiteSpaces } from "@/app/infrastructures/misc/Utils";

@Options({
  props: {
    columns: {
      default: [],
      type: Array
    },
    data: {
      default: [],
      type: Array
    },
    onClick: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onOpenAttachment: {
      default: Function as PropType<() => void>,
      type: Function
    },
    clickOutside: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isOnClick: {
      default: true,
      type: Boolean
    },
    isOpenAttachment: {
      default: true,
      type: Boolean
    }
  }
})
export default class Table extends Vue {
  replaceWhiteSpaces = replaceWhiteSpaces;
}
