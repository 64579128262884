
import { initialName } from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "InitialName",
  props: {
    name: {
      default: "",
      type: String
    },
    email: {
      default: "",
      type: String
    }
  }
})
export default class extends Vue {
  initialName(name: string): any {
    return initialName(name);
  }
}
