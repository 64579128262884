
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class Client extends Vue {
  // Tabs
  tabs = [
    { name: "existing-data", title: "Data Tersedia" },
    { name: "template", title: "Template" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
