
import { Vue, Options } from "vue-class-component";
import { BulkController } from "@/app/ui/controllers/BulkController";
import { BulkUploadType } from "@/app/infrastructures/misc/Utils";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import SelectSearchOriginDestination from "@/app/ui/components/select-search-origin-destination/index.vue";
import { BaseRateController } from "@/app/ui/controllers/BaseRateController";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { RequestApiClientMappingList } from "@/domain/entities/Client";
import debounce from "lodash/debounce";
import Checkbox from "primevue/checkbox";
import { BulkUploadXlsxApiRequest } from "@/data/payload/api/BulkApiRequest";
import RadioButton from "primevue/radiobutton";
import { FlagsPermissionBulkUpload } from "@/feature-flags/bulk-upload";

@Options({
  name: "UploadForm",
  components: {
    SelectSearchOriginDestination,
    UploadFile,
    Checkbox,
    RadioButton
  }
})
export default class extends Vue {
  props: any = this.$props;
  // State Value
  archiveType = "";
  activityName = "";
  versionName = "";

  // Handle File
  archiveFile = "";
  isClearFile = false;

  typeName = "";
  get typeData() {
    return BulkUploadType.filter(item => {
      if (!item.permission) return true;
      else if (typeof item.permission === "string") {
        return checkRolePermission(item.permission);
      }
      return item.permission.isEnabled();
    });
  }
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  statusReset = false;
  onSelectType(name: string, value: string) {
    this.typeName = name;
    this.archiveType = value;
    this.onCloseTypeSelect();
    this.statusReset = true;
    this.resetForm();
    if (this.archiveType.includes("client-location"))
      this.onChooseSelectSearchClientName("BOOKING API TEST CORPORATE", 2407);
  }
  get checkColumn(): { column: string; type: string }[] {
    if (this.archiveType.match(/retail-rate/gi)) {
      return [
        {
          column: "start_weight",
          type: "number"
        },
        {
          column: "end_weight",
          type: "number"
        },
        {
          column: "publish_rate",
          type: "number"
        },
        {
          column: "shipping_surcharge",
          type: "number"
        },
        {
          column: "document_surcharge",
          type: "number"
        },
        {
          column: "origin_forward_rate",
          type: "number"
        },
        {
          column: "destination_forward_rate",
          type: "number"
        }
      ];
    }
    return [];
  }

  // status version
  statusVersion = { name: "Aktif", value: "active" };
  get statusVersionData() {
    return [
      { name: "Aktif", value: "active" },
      { name: "Draft", value: "draft" },
      { name: "For Sales", value: "for-sales" }
    ];
  }
  statusVersionSelect = false;
  onOpenstatusVersionSelect() {
    this.statusVersionSelect = true;
  }
  onClosestatusVersionSelect() {
    this.statusVersionSelect = false;
  }
  onSelectStatusVersion(item: any) {
    this.statusVersion = item;
    this.onSearchVersionName("");
  }

  // Noification
  allNotifications: Array<any> = [];
  onOpenNotification() {
    this.allNotifications.unshift(this.allNotifications.length + 1);
    setTimeout(() => {
      this.allNotifications.pop();
      BulkController.setOpenNotification(false);
    }, 5000);
  }
  get openNotification() {
    return BulkController.openNotification;
  }

  onCloseNotification(i: number) {
    this.allNotifications.splice(i, 1);
  }

  get isLoading() {
    return BulkController.isLoading;
  }

  get message() {
    return BulkController.message;
  }

  get type() {
    return BulkController.type;
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;

  get retailStart(): any {
    return BulkController.convertRetailStart;
  }
  get retailEnd(): any {
    return BulkController.convertRetailEnd;
  }
  inputDateStart(param: Date) {
    BulkController.setRetailStart(param);

    // retail rate can back date
    if (!this.archiveType.includes("retail-rate")) {
      const secondDate = new Date(this.retailEnd);
      this.invalidStartDate =
        param.getTime() > secondDate.getTime() ||
        param.getTime() === secondDate.getTime();
    }
  }

  inputDateEnd(param: Date) {
    BulkController.setRetailEnd(param);
    const secondDate = new Date(this.retailStart);
    if (this.archiveType !== 'update-commodity-surcharge') {
      this.invalidEndDate =
        param.getTime() < secondDate.getTime() ||
        param.getTime() === secondDate.getTime();
    }
  }

  // Version Name
  get isVersionNameAvailable(): boolean {
    return !BaseRateController.baseRatesData.data.find(
      item => item.rateVersionName === this.versionName
    );
  }
  getVersionName(e: any) {
    const value = e.target.value;
    this.versionName = value;
    if (value.length >= 3) {
      this.onSearchVersionName(value);
    }
  }

  // promo status
  archivePromoStatus = "";
  get promoStatusData() {
    return [
      { name: "Aktif", value: "active" },
      { name: "Menunggu", value: "waiting" }
    ];
  }

  // List Version Name
  created() {
    this.onSearchVersionName("");
    this.getListClientName();
  }
  versionNameValue = "";
  isVersionName = false;
  get versionNameList() {
    return BaseRateController.baseRatesData.data.map(e => ({
      name: e.rateVersionName,
      code: e.id,
      ...e
    }));
  }
  onOpenVersionName() {
    this.isVersionName = true;
  }
  onCloseVersionName() {
    this.isVersionName = false;
  }

  // update-retail-rate date
  updateRetail = {
    startDate: "",
    endDate: ""
  };
  onChooseSelectVersionName(_: string, __: string, data: any) {
    this.versionNameValue = data.name;
    this.updateRetail.startDate = data.rateVersionStartDate;
    this.updateRetail.endDate = data.rateVersionEndDate;

    // set fill auto date
    BulkController.setRetailStart(
      new Date(new Date(data.rateVersionStartDate).toLocaleDateString("fr-CA"))
    );
    BulkController.setRetailEnd(
      new Date(new Date(data.rateVersionEndDate).toLocaleDateString("fr-CA"))
    );
    this.isVersionName = false;
  }
  onSearchVersionName(value: string) {
    this.versionNameValue = value;
    BaseRateController.getBaseRatesList({
      search: value,
      status: this.statusVersion.value,
      page: 1,
      limit: 10
    });
  }

  // Client Name
  clientNameDisplay = "";
  clientNameValue = 0;
  isClientName = false;
  onOpenClientName() {
    this.isClientName = true;
  }
  onCloseClientName() {
    this.isClientName = false;
  }
  onChooseSelectSearchClientName(name: string, code: number) {
    this.clientNameDisplay = name;
    this.clientNameValue = code;
    this.isClientName = false;
  }
  onSearchClientName(value: string) {
    this.clientNameDisplay = value;
    if (value.length === 0 || value.length >= 3) {
      this.fetchClientName(value);
    }
  }
  fetchClientName = debounce((value: string) => {
    ClientController._onGetCLientMappingList(
      new RequestApiClientMappingList({
        page: 1,
        limit: 10,
        search: value,
        isBranch: false,
        isParent: true
      })
    );
  }, 500);
  getListClientName() {
    ClientController._onGetCLientMappingList(
      new RequestApiClientMappingList({
        page: 1,
        limit: 10,
        search: "",
        isBranch: false,
        isParent: true
      })
    );
  }
  get clientNameList() {
    return ClientController.clientMappingData.map(e => ({
      name: e.clientName,
      code: e.clientId
    }));
  }

  get isValidate() {
    return (
      this.archiveType &&
      this.activityName.length <= 56 &&
      !!this.activityName &&
      !!this.archiveFile
    );
  }

  get isRetail() {
    return this.versionName && this.isVersionNameAvailable;
  }

  get isUpdateRetail() {
    return this.versionNameValue && this.isVersionNameAvailable;
  }

  get isAddClientName() {
    return !!this.clientNameDisplay;
  }

  get withDate() {
    return (
      this.retailStart &&
      this.retailEnd &&
      !this.invalidStartDate &&
      !this.invalidEndDate &&
      (dateToDateString(this.retailStart) !== dateToDateString(this.retailEnd) || this.archiveType === 'update-commodity-surcharge')
    );
  }

  get retailRate() {
    return (
      this.isRetail && this.isValidate && this.withDate && this.checkAgreement
    );
  }

  get updateRetailRate() {
    return (
      this.isUpdateRetail &&
      this.isValidate &&
      this.withDate &&
      this.checkAgreement &&
      this.rateActiveDate
    );
  }

  get createCommoditySurcharge() {
    return this.isValidate && this.withDate;
  }

  get updateCommoditySurcharge() {
    return this.isValidate && this.withDate && this.statusSurcharge;
  }

  get clientLocation() {
    return this.isValidate && this.withDate && this.isAddClientName;
  }
  get isValidPromoDiscount(): boolean {
    return !!this.isValidate && !!this.archivePromoStatus;
  }

  get isValidCustomStatus(): boolean {
    return !!this.isValidate && !!this.customStatusOption;
  }

  //  Is disabled
  get isDisabled() {
    switch (this.archiveType) {
      case "retail-rate":
        return !this.retailRate;
      case "update-retail-rate":
        return !this.updateRetailRate;
      case "create-commodity-surcharge":
        return !this.createCommoditySurcharge;
      case "update-commodity-surcharge":
        return !this.updateCommoditySurcharge;
      case "client-location":
      case "client-location-update":
        return !this.clientLocation;
      case "update-promo-discount":
        return !this.isValidPromoDiscount;
      case "update-custom-status":
        return !this.isValidCustomStatus;
      default:
        return !this.isValidate;
    }
  }

  async onSubmit() {
    const startDate = this.formatDateBulkUpload().start;
    const endDate = this.formatDateBulkUpload().end;
    if (!this.isDisabled) {
      if (
        this.archiveType.match(/retail-rate|client-location|promo-discount/gi)
      ) {
        await BulkController._onBulkUploadXlsx(
          new BulkUploadXlsxApiRequest({
            archiveType: this.archiveType,
            activityName: this.activityName,
            archiveFile: this.archiveFile,
            versionName:
              this.archiveType === "retail-rate"
                ? this.versionName
                : this.versionNameValue,
            startDate: startDate,
            endDate: endDate,
            clientId: this.clientNameValue,
            isScheduler: this.isScheduler,
            rateActiveDate: this.archiveType.includes("update-retail-rate")
              ? this.$moment(this.rateActiveDate).format("YYYY-MM-DD HH:mm:ss")
              : "",
            archivePromoStatus: this.archivePromoStatus
          })
        );
      } else {
        await BulkController._onBulkUploadCsv({
          archiveType: this.archiveType,
          activityName: this.activityName,
          archiveFile: this.archiveFile,
          startDate: startDate,
          endDate: endDate,
          isScheduler: this.isScheduler,
          customStatus: this.customStatusOption,
          commoditySurchargeStatus: this.statusSurcharge,
          isBulkUploadForm: true
        });
      }
      this.onOpenNotification();
      this.typeName = "";
      this.archiveType = "";
      this.statusReset = false;
      this.resetForm();
      // refresh list
      BulkController.searchActivity("");
      BulkController.selectStatus("");
      BulkController.selectType("");
      BulkController.setFirstPage();
      BulkController.getBulkList({
        activityName: "",
        archiveType: "",
        status: "",
        page: 1,
        limit: 10
      });
    }
  }

  get isScheduler() {
    return (
      this.archiveType === "update-retail-rate" ||
      this.archiveType === "add-city" ||
      this.archiveType === "update-city" ||
      this.archiveType === "add-district" ||
      this.archiveType === "update-district" ||
      this.archiveType === "add-country" ||
      this.archiveType === "update-country"
    );
  }

  resetForm() {
    this.activityName = "";
    this.archiveFile = "";
    this.isClearFile = true;
    this.versionNameValue = "";
    this.versionName = "";
    this.customStatusOption = "";
    this.statusVersion = { name: "Aktif", value: "active" };
    this.clientNameDisplay = "";
    this.archivePromoStatus = "";
    BulkController.setRetailStart(
      new Date(new Date().setDate(new Date().getDate() + 1))
    );
    BulkController.setRetailEnd(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 3
      )
    );
    this.invalidStartDate = false;
    this.invalidEndDate = false;
    this.agreementCityRate = false;
    this.agreementDistrictRate = false;
  }

  directTemplate() {
    this.$router.push("/bulk-action/bulk-download");
  }

  onCloseWithReset() {
    BulkController.setIsOpenUpload(false);
    this.resetForm();
  }

  get configUploadFile() {
    switch (this.archiveType) {
      case "retail-rate":
      case "update-retail-rate":
        return {
          type: "xlsx",
          maxRows: 160000
        };
      case "client-location-update":
      case "client-location":
      case "add-promo-discount":
      case "update-promo-discount":
        return {
          type: "xlsx",
          maxRows: 100000
        };
      case "estimate-sla":
      case "sla-district":
        return {
          type: "csv",
          maxRows: 100000
        };
      case "update-custom-status":
        return {
          type: "csv",
          maxRows: 200
        };
      case "cargo-config":
        return {
          type: "csv",
          maxRows: 502
        };
      case "add-district":
      case "update-district":
        return {
          type: "csv",
          maxRows: FlagsPermissionBulkUpload.config_max_file_district.getValue()
        }
      default:
        return {
          type: "csv",
          maxRows: 25000
        };
    }
  }

  get periodTitle() {
    switch (this.archiveType) {
      case "retail-rate":
        return "Harga Retail";
      case "create-commodity-surcharge":
        return "Create Commodity Surcharge";
      case "update-commodity-surcharge":
        return "Update Commodity Surcharge";
      case "client-location":
      case "client-location-update":
        return "Mapping Location";
      case "add-embargo":
      case "update-embargo":
        return "Embargo";
      default:
        return "Harga Retail";
    }
  }

  get ableToViewPeriode() {
    return (
      this.archiveType.includes("retail-rate") ||
      this.archiveType.includes("create-commodity-surcharge") ||
      this.archiveType.includes("update-commodity-surcharge") ||
      this.archiveType.includes("client-location") ||
      this.archiveType.includes("add-embargo") ||
      this.archiveType.includes("update-embargo")
    );
  }

  formatDateBulkUpload() {
    switch (this.archiveType) {
      case "update-retail-rate":
        return {
          start: this.updateRetail.startDate,
          end: this.updateRetail.endDate
        };
      case "client-location":
      case "client-location-update":
      case "add-embargo":
      case "update-embargo":
        return {
          start: new Date(this.retailStart).toLocaleDateString("fr-CA"),
          end: new Date(this.retailEnd).toLocaleDateString("fr-CA")
        };
      case "retail-rate":
      case "create-commodity-surcharge":
        return {
          start: this.$moment(this.retailStart).toISOString(true),
          end: this.$moment(this.retailEnd).toISOString(true)
        };
      case "update-commodity-surcharge":
        return {
          start: this.$moment(this.retailStart).toISOString(true),
          end: this.$moment(this.retailEnd).toISOString(true)
        };
      default:
        return {
          start: "",
          end: ""
        };
    }
  }

  // check agreement
  agreementCityRate = false;
  agreementDistrictRate = false;
  get checkAgreement() {
    return this.agreementDistrictRate && this.agreementCityRate;
  }

  rateActiveDate = "";

  isExpandCustomStatus = false;
  customStatusOption = "";
  get customStatusOptionData() {
    return [
      { name: "HALCD", value: "HALCD" },
      { name: "CNXCD", value: "CNXCD" },
    ];
  }
  onSelectCustomStatusOption(item: any) {
    this.customStatusOption = item.value;
  }

  statusSurcharge = "";
  get statusSurcharges() {
    return [
      { name: "Aktif", value: "active" },
      { name: "Menunggu", value: "pending" }
    ];
  }

  onChangeStatusSurcharge(value: string) {
    if (value === 'active') {
      this.inputDateStart(new Date(new Date().setDate(new Date().getDate() + 1)));
    }
    if (value === 'pending' && new Date(this.retailStart).getTime() === new Date(this.retailEnd).getTime()) {
      this.inputDateEnd(new Date(new Date(this.retailStart).setDate(new Date(this.retailStart).getDate() + 1)));
    }
    this.statusSurcharge = value;
  }

  get minEndDate() {
    if (this.archiveType === 'update-commodity-surcharge' && this.statusSurcharge === 'active') {
      return new Date(new Date().setDate(new Date().getDate() + 1))
    }
    return new Date(new Date(this.retailStart).setDate(new Date(this.retailStart).getDate() + 1))
  }
}
