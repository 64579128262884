
import { Options, Vue } from "vue-class-component";

@Options({
  name: "OptionsAttachment",
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class extends Vue {
  // Options Attachment
  originalHover = false;
  failedHover = false;
  optionAttachment = false;
  toOriginalHover() {
    window.open(
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
    );
  }
}
