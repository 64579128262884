
import { Options, Vue } from "vue-class-component";
import { downloadCsvFromCsvContent } from "@/app/infrastructures/misc/Utils";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { BulkDownloadController } from "@/app/ui/controllers/BulkDownloadController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { ReasonData } from "@/domain/entities/PodDex";

@Options({
  components: {
    DetailLayout
  }
})
export default class TabExistingData extends Vue {
  //   Search
  searchValue = "";
  onSearch(value: string) {
    this.searchValue = value;
  }
  onClearSearch() {
    this.searchValue = "";
  }

  get filteredStepFillTemplate() {
    return this.tempList.filter(item =>
      this.searchValue
        ? item.title.toLowerCase().includes(this.searchValue.toLowerCase())
        : true
    );
  }

  get accountType() {
    return AccountController.accountData.account_type;
  }
  get detailAccountType() {
    return AccountController.accountData.account_type_detail.type;
  }

  stepFillTemplate = [
    {
      title: "Permission List",
      description: "Data Permision List",
      service: "horde/v1",
      type: "account/role/permission/list/export_csv"
    },
    {
      title: "Role",
      description: "Data Role",
      service: "horde/v1",
      type: "account/role/list/export_csv"
    }
  ];

  internalTemplate = [
    {
      title: "eCargo Commodity",
      description: "Data eCargo Commodity",
      service: "horde/v1",
      type: "commodity/group/export_csv"
    },
    {
      title: "Commodity",
      description: "Data commodity",
      service: "horde/v1",
      type: "commodity/export_csv"
    },
    {
      title: "Location Country",
      description: "Data location country",
      service: "horde/v1",
      type: "country/export_csv"
    },
    {
      title: "Location City",
      description: "Data location city",
      service: "horde/v1",
      type: "city/export_csv"
    },
    {
      title: "Location District",
      description: "Data location district",
      service: "horde/v1",
      type: "district/export_csv"
    },
    {
      title: "Vendor",
      description: "Data vendor",
      service: "horde/v1",
      type: "vendor/export_csv"
    },
    {
      title: "Transport Vendor",
      description: "Data Transaport Vendor",
      service: "horde/v1",
      type: "transport/export_csv"
    },
    {
      title: "Leg",
      description: "Data Leg",
      service: "horde/v1",
      type: "leg/export_csv"
    },
    {
      title: "Route",
      description: "Data Route",
      service: "horde/v1",
      type: "route/export_csv"
    },
    {
      title: "Route Leg",
      description: "Data Route Leg",
      service: "horde/v1",
      type: "route/route-leg/export_csv"
    },
    {
      title: "SLA District",
      description: "Data SLA District",
      service: "horde/v1",
      type: "district/export_csv/sla"
    },
    {
      title: "SLA City",
      description: "Data SLA City",
      service: "horde/v1",
      type: "estimate/export_csv"
    },
    {
      title: "Cargo Config",
      description: "Data Cargo Config",
      service: "horde/v1",
      type: "cargo-configuration/export_csv"
    },
    {
      title: "AWB Reserve",
      description: "Data AWB Reserve",
      service: "hydra/v1",
      type: "cargo-reserve/export_csv"
    },
    {
      title: "Region",
      description: "Data Region",
      service: "horde/v1",
      type: "region/export-csv"
    },
    {
      title: "Region City",
      description: "Data Region City",
      service: "horde/v1",
      type: "region/region-city/export-csv"
    },
    {
      title: "Kargo Grouping",
      description: "Data Kargo Grouping",
      service: "horde/v1",
      type: "rtc_city_group/export_csv"
    },
    {
      title: "Reason HALCD",
      description: "Data Reason HALCD",
      service: "hydra/v1/reason",
      type: ""
    }
  ];

  get tempList() {
    if (this.accountType) {
      if (this.accountType === "internal") {
        return [...this.stepFillTemplate, ...this.internalTemplate];
      }
      if (this.accountType === "client" || this.detailAccountType === "pos") {
        return [
          ...this.stepFillTemplate,
          this.internalTemplate[1],
          this.internalTemplate[4]
        ];
      }
      return this.stepFillTemplate;
    }
    return [];
  }

  errorExport = false;
  async downloadExisting(service: string, type: string, title: string) {
    MainAppController.showLoading();

    let resp = {
      success: false,
      data: ""
    };
    if (title === "Reason HALCD") {
      await PodDexController.getReasonList(
        new ReasonListDex({
          statusCode: "HALCD",
          reasonStatus: "active"
        })
      );
      const header = "reason_code,reason_description";
      const data = PodDexController.reasonList.map(
        (item: ReasonData) => `${item.reasonCode},${item.reasonDescription}`
      );

      resp = {
        success: true,
        data: [header, ...data].join("\n")
      };
    } else {
      resp = await BulkDownloadController.getExistingData({
        service: service,
        type: type
      });
    }
    MainAppController.closeLoading();
    this.errorExport = !resp.success;
    if (resp.success) {
      downloadCsvFromCsvContent({
        fileName: `${title}.csv`,
        csvContent: resp.data
      });
    }
  }
}
