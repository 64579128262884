import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-3 items-center" }
const _hoisted_2 = { class: "flex flex-col text-left" }
const _hoisted_3 = { class: "text-12px xxl:text-14px text-black-lp-200" }
const _hoisted_4 = { class: "text-11px text-gray-lp-1000" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: 
        `${
          _ctx.initialName(_ctx.name).color
        } w-8 h-8 font-medium text-12px xxl:text-14px tracking-wider flex flex-col justify-center items-center text-white rounded-full`
      
    }, _toDisplayString(_ctx.initialName(_ctx.name).initial), 3),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createVNode("div", _hoisted_4, _toDisplayString(_ctx.email), 1)
    ])
  ]))
}