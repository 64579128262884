/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { BulkDownloadPresenter } from "../presenters/BulkDownloadPresenter";

export interface BulkDownloadState {
  isLoading: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "bulk_download"
})
class BulkDownloadStore extends VuexModule implements BulkDownloadState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";

  @Action
  public getExistingData(params: { service: string; type: string }) {
    const presenter = container.resolve(BulkDownloadPresenter);
    return presenter
      .getExistingData(params.service, params.type)
      .then((res: string) => {
        return {
          data: res,
          success: true
        };
      })
      .catch((error: any) => {
        this.setErrorCause(error.response ? "server" : "internet");
        return {
          data: "",
          success: false
        };
      });
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }
}

export const BulkDownloadController = getModule(BulkDownloadStore);
