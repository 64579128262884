
import { Options, Vue } from "vue-class-component";
import Home from "@/app/ui/components/icons/modules/home.vue";
import UploadForm from "./component/upload-form.vue";
import { BulkController } from "@/app/ui/controllers/BulkController";
import TableBulkUpload from "./component/table.vue";
import { BulkUploadType, formatDate } from "@/app/infrastructures/misc/Utils";
import debounce from "lodash/debounce";
import OptionsAttachment from "./component/attachment.vue";
import Detail from "./component/detail.vue";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import { BULK_UPLOAD } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    Home,
    UploadForm,
    TableBulkUpload,
    OptionsAttachment,
    Detail
  },
  computed: {
    isCreateAble() {
      return checkRolePermission(BULK_UPLOAD.CREATE) && !this.isError;
    }
  }
})
export default class BulkUpload extends Vue {
  type = "";
  get typeData() {
    return BulkUploadType.filter(item => {
      if (!item.permission) return true;
      else if (typeof item.permission === "string") {
        return checkRolePermission(item.permission);
      }
      return item.permission.isEnabled();
    });
  }

  get columns() {
    return [
      {
        name: "Upload ID",
        key: "id",
        styleHead: "w-1/12 text-center whitespace-nowrap",
        styleBody: "text-red-lp-200",
        render: (item: any) => {
          return `<span class='text-center justify-center flex'>${item.id}</span>`;
        }
      },
      {
        name: "Nama Aktivitas",
        key: "namaAktivitas",
        styleHead: "w-2/12 lg:w-3/12 text-left px-4",
        styleBody: "text-center rounded px-2 py-0",
        render: (item: any) => {
          return `<div class='flex'>${item.activityName}</div>`;
        }
      },
      {
        name: "Tipe Berkas",
        key: "archiveType",
        styleHead: "w-2/12 text-left",
        styleBody: "",
        render: (item: any) => {
          return `<div class="text-black-lp-200 flex">
            <div class="rounded px-2 py-0 bg-gray-lp-400">
              <span class="capitalize whitespace-nowrap">${item.activityType}</span>
            </div>
          </div>`;
        }
      },
      {
        name: "Waktu Dibuat",
        key: "createdAt",
        styleHead: "w-3/12 text-left",
        styleBody: "",
        render: (item: any) => {
          return `<div class="flex flex-col"><span class="whitespace-nowrap">
              ${formatDate(item.createdAt)}</span>
            <span class="text-xs ${
              item.createdBy ? "text-gray-lp-500 inline-block" : "hidden"
            }">${item.createdBy || "User"}</span></div>`;
        }
      },
      {
        name: "Status",
        key: "status",
        styleHead: "w-2/12 text-left",
        styleBody: "",
        render: (item: any) => {
          return `
        <div class="flex"> <span class="capitalize text-center rounded-full text-12px xxl:text-14px px-2 whitespace-nowrap
              ${item.status.toLowerCase() === "success" &&
                "bg-green-lp-300 text-green-lp-200"}
                ${(item.status.toLowerCase() === "partially" ||
                  item.status.toLowerCase() === "waiting") &&
                  "bg-yellow-lp-200 text-yellow-lp-100"}
                 ${item.status.toLowerCase() === "failed" &&
                   "bg-red-300 text-red-lp-100"} ,
                 ${item.status.toLowerCase() === "uploading" &&
                   "bg-gray-lp-200 text-black-lp-300"}"
              >
              ${item.status} ${
            this.isShowCounting(item.status)
              ? item.dataProcessed + "/" + item.totalData
              : ""
          }</span></div>`;
        }
      },
      {
        name: "Lampiran",
        key: "lampiran",
        styleHead: "w-1/12 text-center whitespace-nowrap",
        styleBody: "mx-20",
        render: () => {
          return;
        }
      }
    ];
  }

  isShowCounting(status: string) {
    switch (status.toLowerCase()) {
      case "waiting":
      case "failed":
        return false;

      default:
        return true;
    }
  }

  isAbleDownloadFailedAttachment(status: string, isFailedFile: boolean) {
    let isShow = false;
    switch (status.toLowerCase()) {
      case "success":
      case "partially":
      case "failed":
        isShow = true;
        break;
    }

    return isFailedFile && isShow;
  }

  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  typeName = "";
  onSelectType(name: string, value: string) {
    BulkController.selectArchiveType(value);
    this.onCloseTypeSelect();
    this.setFirstPage();
    this.typeName = name;
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Uploading", value: "Uploading" },
    { name: "Partially", value: "partially" },
    { name: "Success", value: "success" },
    { name: "Failed", value: "failed" }
  ];
  statusSelect = false;

  async created() {
    this.getBulkList();
    this.setFirstPage();
    await BulkController.searchActivity("");
    await BulkController.selectStatus("");
  }

  getBulkList() {
    BulkController.getBulkList({
      activityName: "",
      archiveType: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  public get bulkListData() {
    return BulkController.bulkData.bulkData;
  }

  public get isLoadingListData() {
    return BulkController.isLoadingListData;
  }

  public get page() {
    return BulkController.page;
  }

  public get limit() {
    return BulkController.limit;
  }

  public get activityName() {
    return BulkController.activity_name;
  }

  public get archiveType() {
    return BulkController.archive_type;
  }

  public get status() {
    return BulkController.status;
  }

  onSearch(value: string) {
    BulkController.search(value);
    this.setFirstPage();
  }

  public setFirstPage() {
    return BulkController.setFirstPage();
  }

  public clearSearch() {
    return BulkController.clear();
  }

  nextPage() {
    BulkController.setNextPage();
  }

  prevPage() {
    BulkController.setPrevPage();
  }

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  statusName = "";
  onSelectStatus(name: string, value: string) {
    BulkController.selectStatusUpload(value);
    this.onCloseStatusSelect();
    this.setFirstPage();
    this.statusName = name;
  }

  get isOpenUpload() {
    return BulkController.isOpenUpload;
  }

  onOpenUpload() {
    BulkController.setIsOpenUpload(true);
  }

  public onClick() {
    window.open("_blank");
  }
  toPage = debounce(value => {
    BulkController.setPageAction(value);
  }, 500);

  //Error
  get isError() {
    return BulkController.isError;
  }

  //Error Cause
  get errorCause() {
    return BulkController.isErrorCause;
  }

  // Error upload
  get isErrorUpload() {
    return BulkController.isErrorUpload;
  }

  get isErrorCauseUpload() {
    return BulkController.errorUploadConnection;
  }

  handleErrorClose() {
    BulkController.handleError();
  }

  // Options Attachment
  optionsAttachment = false;
  rowData = {
    row: 0,
    cacheRow: 0,
    id: 0,
    activityName: "",
    status: "",
    archiveFile: "",
    failedFile: ""
  };
  originalHover = false;
  failedHover = false;
  setOptionsAttachment(item: any, index: number, isActive: boolean) {
    if (this.rowData.cacheRow === index) this.optionsAttachment = isActive;
    else this.optionsAttachment = true;

    this.rowData = {
      row: index,
      cacheRow: index,
      id: item.id,
      activityName: item.activityName,
      status: item.status,
      archiveFile: item.archive_file,
      failedFile: item.failed_file
    };
  }
  toOriginal() {
    const fileName = this.rowData.archiveFile;
    const fileNameSplited = fileName.split(".");
    const lengthOffileNameSplited = fileNameSplited.length;
    const extension = fileNameSplited[lengthOffileNameSplited - 1];

    downloadFile(
      this.rowData.archiveFile,
      this.rowData.activityName,
      `.${extension}`
    );
  }
  toFailed() {
    window.location.href = this.rowData.failedFile;
  }

  // Screen Size
  get screenFHD() {
    return window.matchMedia("(min-width: 1920px)").matches;
  }

  // Detail Page
  isOpenDetail = false;
  toDetail(data: any) {
    if (checkRolePermission(BULK_UPLOAD.DETAIL)) {
      this.optionsAttachment = false;
      this.isOpenDetail = true;
      BulkController._onGetDetail(data?.id);
    } else {
      this.isOpenDetail = false;
    }
  }
  onCloseDetail() {
    this.isOpenDetail = false;
  }
}
